<template>
  <v-card color="grey lighten-4" elevation="1">
    <v-card-text>
      <v-row class="text-caption">
        <v-col cols="12" sm="auto">
          <span class="font-weight-bold">Código interno (IES):</span
          ><span class="ml-2">{{ formData.code || "-" }}</span>
        </v-col>
        <v-col cols="12" sm="auto">
          <span class="font-weight-bold">Caducidad formación:</span
          ><span class="ml-2">{{
            (formData.training_course_validity_expiration &&
              $moment(formData.training_course_validity_expiration).format("L")) ||
            "-"
          }}</span>
        </v-col>
        <v-col cols="12" sm="auto">
          <span class="font-weight-bold">Última aceptación RGPD:</span
          ><span class="ml-2">{{ lastRgpdAccetanceDate }}</span>
        </v-col>
        <v-col cols="12" sm="auto">
          <span class="font-weight-bold">Creación:</span
          ><span class="ml-2"
            >{{ $moment(formData.date_created).format("L") }} por {{ formData.user_created }}</span
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    formData: { type: Object, required: true },
  },
  methods: {
    getExpiryDateColor(date) {
      if (!date) return "grey";
    },
  },
  computed: {
    lastRgpdAccetanceDate() {
      return this.formData.last_rgpd_acceptance
        ? this.$moment(this.formData.last_rgpd_acceptance).format("L")
        : "-";
    },
  },
};
</script>

<style scoped></style>
